// COLORS //

$colour-green: #1b7125;
$colour-cyan: #d1f6fb;
$colour-cyan-light: #e3fbfe;
$colour-purple: #54127b;
$colour-purple-light: #d3b6e4;
$colour-error: #ed1414;
$colour-sky-blue: #A5D7F3;

$colour-white: #fcfcfc;

// FONTS //

@import url("https://use.typekit.net/ufh6jnp.css");

$font-body: brandon-grotesque, sans-serif;


// BREAKPOINTS //

$xs: 400px;
$phone: 700px;
$tablet: 850px;
$desktop: 1366px;
$tv: 2000px;

// SPACING
$spacing-text: 1.5em;