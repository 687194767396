@import "./variables.scss";

$transition-time: .2s;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

// SCROLLBAR //

::-webkit-scrollbar {
  background: $colour-sky-blue;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: $colour-purple;
}

@supports (-moz-appearance: none) {
  :root {
    scrollbar-color: $colour-purple $colour-purple !important;
    scrollbar-width: auto !important;
  }
}

.App {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: 180%;
  overflow: hidden;
  @media screen and (max-width: $desktop) {
    background-size: cover;
  }
}

body {
  margin: 0;
  font-family: $font-body;

  // LOGOBOX ANIMATION //

  // VARIABLES //

  $scale: 1.05;
  $rotate: 5deg;
  $animationTime: 8s;

  @keyframes logoBoxBounce {
    0% {
      transform: scale(1) rotate(0);
    }
    45% {
      transform: scale(1) rotate(0);
    }
    50% {
      transform: scale($scale) rotate(0);
    }
    51% {
      transform: scale($scale) rotate($rotate);
    }
    52% {
      transform: scale($scale) rotate(-$rotate);
    }
    53% {
      transform: scale($scale) rotate(0);
    }
    58% {
      transform: scale(1) rotate(0);
    }
    100% {
      transform: scale(1) rotate(0);
    }
  }
  div.logobox {
    width: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 175px 0 175px 15%;
    gap: 40px;
    animation: logoBoxBounce $animationTime ease infinite;
    img {
      width: 100%;
    }
    @media screen and (max-width: $desktop) {
      width: 70%;
      margin: auto auto 12.5% auto;
      gap: 15px;
      img.logo {
        width: 100%;
      }
    }
    @media screen and (min-width: $tv) {
      margin-left: 20%;
      width: 650px;
    }
  }
  #logobox-centered {
    margin: 175px auto;
    @media only screen and (max-width: $desktop) {
      margin: 75px auto;
    }
  }
  h1,
  h2 {
    margin: 0;
  }
  h1,
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.125rem;
  }
  .content-wrapper {
    padding: 3.25% 9.8%;
    // width: 65%;
    width: 1000px;
    background-color: $colour-cyan-light;
    color: $colour-green;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $tv) {
      width: 1500px;
      font-size: 1.5rem;
    }
    @media screen and (max-width: $desktop) {
      width: 90%;
    }
    @media screen and (max-width: $phone) {
      min-height: 19vh;
    }
  }
  form {
    text-align: left;
    display: flex;
    flex-direction: column;
    label {
      font-size: 1.125rem;
      width: fit-content;
    }
    select {
      width: 100%;
    }
    select,
    input {
      margin: 0;
      padding: 0 16px;
      border: 2px solid $colour-green;
    }
    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="tel"]:focus,
    select:focus {
      outline-color: $colour-green;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      color: $colour-green;
      font-weight: bold;
    }
    i {
      height: 22.5px;
      width: 22.5px;
      background-color: $colour-error;
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      color: white;
      font-style: normal;
      font-size: 20px;
      align-items: center;
    }
    .form-wrapper__input {
      input {
        width: 100%;
      }
    }
    .form-wrapper__box {
      display: flex;
      justify-content: space-between;
      div {
        color: $colour-error;
        display: flex;
        gap: 5px;
        margin-left: 5px;
        p {
          font-weight: 300;
          letter-spacing: 0.5px;
          width: fit-content;
        }
      }
      input {
        width: 100%;
      }
      p {
        margin: 0;
      }
    }
    .form-wrapper__checkboxes {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .form-wrapper__checkbox {
        gap: 10px;
        display: flex;
        align-items: center;
        input {
          height: 20px;
          width: 20px;
          margin: 0;
          padding: 0;
          border: none;
            transition: $transition-time ease-in-out;
        }
        select {
            width: 100%;
            option {
                font-size: 1.125rem;
                font-family: $font-body;
                font-weight: 300;
                &:hover {
                    color: red;
                }
            }
        }
        select,
        input[type=text],
        input[type=email],
        input[type=tel] 
        {
            margin: 0;
            padding: 0 16px;
            border: 2px solid $colour-green;
            background-color: $colour-white;

        }
        input[type=text]:focus,
        input[type=email]:focus,
        input[type=tel]:focus,
        select:focus {
            outline-color: $colour-green;
            font-weight: bold;
        }
        input[type=text],
        input[type=email],
        input[type=tel] {
            color: $colour-green;
        }
        i {
          height: 22.5px;
          width: 22.5px;
          background-color: $colour-error;
          border-radius: 100%;
          display: inline-flex;
          justify-content: center;
          color: white;
          font-style: normal;
          font-size: 20px;
          align-items: center;
        }
        .form-wrapper__input {
            input {
                width: 100%;
            }
        }
        .form-wrapper__box {
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: $desktop) {
                flex-direction: column;
                gap: 5px;
            }
            div {
                color: $colour-error;
                display: flex;
                gap: 5px;
                margin-left: 5px;
                p {
                    font-weight: 300;
                    letter-spacing: .5px;
                    width: fit-content;
                }
            }
            input {
                width: 100%;
            }
            p {
                margin: 0;
            }
        }
        .form-wrapper__checkboxes {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .form-wrapper__checkbox {
                gap: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                input {
                    height: 30px;
                    width: 30px;
                    margin: 0;
                    border: none;
                }
            }
            .check-and-label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                @media only screen and (max-width: $phone) {
                  align-items: flex-start;
                }
            }
        }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
form p,
label {
  font-weight: 700;
  font-style: normal;
}

p {
  font-weight: 400;
  font-style: normal;
  line-height: 1.31;
}

p.light {
  font-weight: 300;
}

footer {
  background-color: $colour-green;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  div {
    width: 80%;
    max-width: 750px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a:hover {
    text-decoration: underline;
  }
  a.footer-logo {
    width: 25%;
    position: relative;
    right: 26px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: $phone) {
    a {
      width: min-content;
    }
    a.footer-logo {
      width: auto;
      display: flex;
      justify-content: center;
      right: unset;
      img {
        width: 80%;
      }
    }
  }
}

input,
select {
  width: 100%;
  height: 25px;
  color: $colour-green;
}

.input-correct {
  border: 1px solid $colour-green;
}

.input-error {
  border: 2px solid $colour-error;
}

$buttonTransition: 0.34s ease;

button, .button-link {
    background-color: $colour-purple;
    color: $colour-white;
    border: 2px solid;
    border-color: $colour-purple;
    border-radius: 10px;
    // padding: 9.5px 24px;
    text-transform: uppercase;
    cursor: pointer;
    align-self: center;
    letter-spacing: .5px;
    margin: 10px 0;
    font-family: $font-body;
    font-size: 1.2rem;
    transition: $buttonTransition;
    min-width: 120px;
      height: 40px;
      z-index: 4;
    &:hover {
        // background-color: $colour-cyan-light;
        background-color: $colour-white;
        color: $colour-purple;
        text-shadow: 0 0 .85px $colour-purple, 0 0 .85px $colour-purple;
        // font-weight: 600;
        a {
            color: $colour-purple;
            transition: none;
            // For some reason when transition is applied it has a nasty delay only on links and not buttons for some reason //
        }
    }
    &:active {
        // background-color: $colour-cyan-light;
        background-color: $colour-purple-light;
        color: $colour-purple;
        text-shadow: 0 0 .85px $colour-purple, 0 0 .85px $colour-purple;
        // font-weight: 600;
        a {
            color: $colour-purple;
            transition: none;
            // For some reason when transition is applied it has a nasty delay only on links and not buttons for some reason //
        }
    }
    @media only screen and (max-width: $desktop) {
        width: 180px;
    }
}

.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: $colour-white;
  text-decoration: none;
  line-height: 100%;
}

.form-wrapper {
  width: 100%;
}

.form-wrapper__box {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 5px;
}

.error-text {
    transition: $transition-time ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: $desktop) {
        justify-content: flex-start;
    }
    i {
        aspect-ratio: 1/1;
        @media only screen and (max-width: $desktop) {
            display: none;
        }
    }
    p {
        margin: 0 0 0 10px;
        color: $colour-error;
        text-align: right;
        @media only screen and (max-width: $desktop) {
            text-align: left;
        }
    }
}

.form-wrapper__checkboxes {
    margin: 20px 0 0;
}

.form-wrapper__checkbox {
  display: flex;
  margin-bottom: 5px;
  input {
    width: 25px;
    height: 25px;
    margin: 0 5px 0 0;
  }
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  border: 3px solid $colour-green;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  transition: 0.2s;
  padding: 0;
}
input[type="checkbox"]:after {
  display: none;
}
input[type="checkbox"]:hover {
  background-color: $colour-white;
  border-radius: 5px;
  cursor: pointer;
}
@media only screen and (max-width: $tablet) {
  input[type="checkbox"]:hover {
    background-color: $colour-white;
  }
}

input[type="checkbox"]:checked {
  height: 20px;
  width: 20px;
  display: block;
  border-radius: 5px;
  margin: 0 $spacing-text * 0.75 0 0;
  cursor: pointer;
  position: relative;
  padding: 0;
}
input[type="checkbox"]:checked:after {
  display: block;
  position: absolute;
  content: "";
  background-image: url("./svg/tick.svg");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  top: 0;
  background-size: 14px 14px;
}
@media only screen and (max-width: $tablet) {
  input[type="checkbox"]:checked:after {
    transform: scale(80%);
  }
}

.athletes-wrapper {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: 125% 50px;
  background-attachment: fixed;
  // margin-bottom: 30px;
  #athletes-bottom-image {
    width: 100%;
    height: clamp(300px, 100vw, 4000px);
    object-fit: cover;
    object-position: 0 -50%;
    margin: -400px 0 -10px 100px;
    z-index: 0;
    @media only screen and (max-width: $desktop) {
      margin: -200px 0 -10px 50px;
    }
    @media only screen and (max-width: $tablet) {
      margin: -100px 0 -10px 50px;
    }
    @media only screen and (max-width: $phone) {
      margin: -100px 0 -10px 25px;
    }
  }
}

#loading-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  // background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  filter: brightness(75%);
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
        backdrop-filter: blur(4px) brightness(50%);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: clamp(125px, 15%, 450px);
        }
        p {
         text-align: center;
         font-size: 2rem;
         color: $colour-white
        }
    }
    p {
      text-align: center;
      font-size: 2rem;
      color: $colour-white;
    }
  }
}

.check-and-label {
  display: flex;
  align-items: center;
  gap: 5px;
  @media only screen and (max-width: $phone) {
    align-items: flex-start;
  }
}

input[type="text"],
    input[type="email"],
    select,
    input[type="tel"] {
      height: 40px;
    }


}
